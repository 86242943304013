import validate from "/home/server/builds/landings-2024-08-21-14-44/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45global from "/home/server/builds/landings-2024-08-21-14-44/middleware/route.global.ts";
import utm_45global from "/home/server/builds/landings-2024-08-21-14-44/middleware/utm.global.ts";
export const globalMiddleware = [
  validate,
  route_45global,
  utm_45global
]
export const namedMiddleware = {
  error: () => import("/home/server/builds/landings-2024-08-21-14-44/middleware/error.ts"),
  landing: () => import("/home/server/builds/landings-2024-08-21-14-44/middleware/landing.ts")
}